@import "fonts";
@import "variables";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
}

main {
  overflow: hidden;
}
